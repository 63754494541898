export default [
  /* */
  { name: '城市之森', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '繁華之心', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '以靜御繁', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '樂感好宅', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '建材選品', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
 
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:-100, mobileOffset: 0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
]
