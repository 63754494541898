import { isMobile } from '../utils/index'
export default {
  address: '桃園市中壢區民族路六段360號對面',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3617.1168196807002!2d121.1563332150049!3d24.962139784006087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3468253da5118539%3A0x1fb24f4c54e19faa!2z5a6c6Kqg5qiC6IGa!5e0!3m2!1szh-TW!2stw!4v1644475897821!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/w5r8TteuQCeRvJ9W6',
  phone: '03-490-9129',
  fbLink: 'https://www.facebook.com/108931748200345/',
  fbMessage: 'https://m.me/108931748200345',
  caseName: '宜誠樂聚',
  indigatorLength: 10,
  caseId: 'yclj',

  houseInfos: [
    ['建設公司', '宜誠建設股份有限公司'],
    ['代銷公司', '明創廣告有限公司'],
    ['基地面績', '約1945坪'],
    ['棟戶規劃', '地上15層 地下三層 232戶'],
    ['基地地址', '桃園市民族路六段&高洲五街'],
    ['接待中心', '桃園市中壢區民族路六段360號對面'],
  ],
  gtmCode: ['MKBRF44'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '歡迎預約',
    subTitle: '若想了解建案資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！'
  }
}
