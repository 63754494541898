<template>
  <div class="section1">


    <img loading="lazy" src="./s1/img.jpg" data-aos="fade" :alt="`${info.caseName}_img`" class="img">
    
    <svg class="logo" xmlns="http://www.w3.org/2000/svg" data-aos="zoom-out" viewBox="0 0 452 550" >
<path class="st0" d="M292.7,70.5C292.7,70.5,292.7,70.5,292.7,70.5c-2.3,0.2-5.8,0.5-10.8,0.7c-4.9,0.3-4.6,8.3,5.5,7.7
	c16.1-1,34.2-2.8,60.5-6l0,8.1c0.2,4.7,11.3,4.7,11.5-0.1l0-64.8h3.9c4.2,0.2,4.2-7.9,0-7.7h-79.6c-4.2-0.2-4.2,7.9,0,7.7h8.8
	c0,0,0,0,0.1,0c0,0,0,0.1,0,0.1L292.7,70.5z M304.2,47.9V35.4l43.8,0v12.5c0,0,0,0,0,0L304.2,47.9C304.2,47.9,304.2,47.9,304.2,47.9
	z M348,27.7l-43.8,0V16.2H348V27.7z M348,55.3l0,9.3c-13.5,1.8-28.5,3.4-43.8,4.9V55.9c0-0.4,0.1-0.5,0.1-0.5c0,0,0,0,0,0L348,55.3z
	 M346.3,121.3c-15.1,16.8-33.1,29.8-53.9,38.6c-5.9,2.5-14.3-3.4-9.7-5.2c20.5-8.2,38.2-21.2,53.5-37.6
	C338.7,114.4,351.4,115.6,346.3,121.3z M441.1,84.2c-15.3-5.8-27.6-13.1-37.6-22.3c-7.9,4.4-16.8,7.8-26.1,10.7
	c-6,1.8-14-5.4-9.6-6.7c9-2.7,19.5-5.7,28.8-10.9c-8.3-9.2-17.4-22.1-21.7-30.7c-1.3-5.1,9.4-4.6,11.1-2
	c3.5,6.6,11.5,17.6,19.5,26.9c13.8-9.7,23-20.2,28.9-33.1h-60.3c-4.2,0.2-4.2-7.9,0-7.7h69.8c3.9-0.2,3.6,4.9,2.4,7.3
	c-6.7,16-18.2,29.6-34.3,40.4c9.5,9,21.9,16.4,36.7,22l0.5,0.3C454.6,83.9,445.3,85.6,441.1,84.2z M337.6,85.9
	c-12.9,13.7-26,22-44,29.7c-3.2,2.3-14.7-1.9-10-5c17.3-7.1,30.8-15.6,43.8-29C331.2,77.5,344.3,78.7,337.6,85.9z M381.9,92.2
	c3.6,6.5,8.4,13.5,13.9,20.1c11-6.3,19.7-12.3,29.8-21.6c3-3.7,16.1-5.5,12.1,2.5c-11.5,10.9-22.1,18.5-35.6,26
	c13,13.9,28.3,25.7,45.5,35.2c6.6,5.9-5.1,8-9.8,5c-26.2-14.5-50.9-36.9-65.5-62c-0.4,0.2-1.2,0.6-1.4,0.6v61.9
	c0.2,4.5-11.6,4.9-11.8,0.1l0-56.8c-10.2,4.4-20.1,8.8-30.4,10c-12.4,1.4-9.9-5.5-7.1-6.2c25.1-6,47.8-16.4,66.3-30.1
	c3.9-3.3,15.9-2.8,11.1,3.8C393.4,85.1,387.6,89,381.9,92.2 M117.3,62c0.2-0.3,0.2-0.7-0.1-1.2c-8.7-12.1-15.6-21.5-20.6-28
	c-3-6.1,8-5.6,10-3.1c3.8-6.7,7.8-14.4,11.8-23c1.8-2.8,11.9-3.3,10.6,2.1c-4.6,9.7-14.1,26.9-15.3,29.1c-0.1,0.5,0,1,0.1,1.1
	c3.2,4.3,7.3,9.9,9.3,12.6c2.4-4.5,10.8-20.4,15.3-29.9c1.9-2.7,12.3-2.9,10.4,2.6c-8.8,19-19.4,38.3-31.5,57.4l21.3-2.5l-3.9-20.9
	c-0.5-1.5,0.2-3,2-3.7c2.6-1.1,7.4-0.6,8.6,1.5l0.1,0.2c0.6,2,6.9,35.5,7,35.9c1.4,4.8-9,5.8-10.5,2.4c-0.4-1.3-1.6-8.3-1.6-8.3
	L100,91.2c-2,0.8-7.5-5.8-1.5-7.1l5.2-0.5c0.2,0,0.5-0.2,0.8-0.7L117.3,62z M225.6,39.1c-1-1.3,3.8-6.1,15.6-29.3
	c3-5.8-8-6.3-10.4-3.2c-3.8,7.8-8,15.3-12.7,23.2c-2.8-3.4-6.7-2.7-8.4-1.6c-1.3-4.9-6.3-8.8-11.4-8.6h-13.6c1.6-3.6,3.3-8.7,5.2-15
	c1.6-5.4-10.1-5.3-11.3-1.7c-4.9,14.6-5.8,16.7-6.5,16.7h-8.3c-6.1-0.2-12,5.4-11.8,11.5v47.8c-0.1,6.5,5.9,11.6,12.5,11.5h10.8v12
	H99.5c-4.4-0.4-4.4,9.3,0,8.9h71.2c-17,15-39.2,27.9-71.8,41.8c-7.5,4.4,4.8,9.3,8.8,6.8c24.7-10.5,50-22.5,67.6-39.1l0,41.2
	c0.3,4.4,11.4,4.5,11.7,0v-42.3c14.7,13.6,35.3,25.5,70.4,40.7c2.5,0.9,14.7-0.3,8-5.5c-26.3-13.6-51.9-20.1-73.4-43.7h68.2
	c4.1,0.4,4.1-9.3,0-8.9h-73.2v-12h11.4c2.7,0,5.2-0.9,7.3-2.5c0.5,1.8,2.6,3.9,4.3,3.8l46-5.2c0.7,2.1,2.2,7.4,2.5,8.5
	c1.2,3.7,12.3,3.6,11.1-1.6c-1.1-3.7-10.2-33.9-11.1-36.6c-1.4-4.8-12.3-3.9-10.8,1.8l6.2,20.8c0,0,0,0,0,0c0,0,0,0,0,0l-24.6,2.7
	c12.7-19.1,23.9-38.4,33.2-57.5c1.8-5.6-8.7-5.2-10.8-2.6c-4.7,9.4-13.1,24.6-16.1,30L225.6,39.1z M229.4,62
	c-3.7,6.2-8.5,13.8-13.8,21.3c-0.2,0.3-6.8,0.7-6.8,0.7c0.9-1.6,1.3-3.3,1.3-5.2V35.9c4.8,6.1,11.3,14.5,19.2,24.9
	C229.7,61.4,229.6,61.7,229.4,62z M198.5,27.6v21.9h-34.9V27.6H198.5 M163.6,57h34.9v25.2h-34.9V57 M32.5,164.6h-5.1
	c-0.5,0-0.7-0.3-0.7-0.9l0-12.1c0-0.6,2.2-0.6,2.2,0l0,10.7l3.5,0C33,162.4,33,164.6,32.5,164.6z M40.4,164.6h-5.5
	c-0.5,0-0.7-0.3-0.7-0.9v-11.6c0-0.5,0.2-0.8,0.7-0.9h5.5c0.4,0,0.4,2.1,0,2.1l-3.9,0v3.3h3.3c0.5,0,0.5,2.1,0,2.1h-3.3v3.8h4
	C40.8,162.5,40.8,164.6,40.4,164.6z M50.6,154.3c-0.7-0.6-1.8-1.1-3.1-1.1c-2.4,0-3.5,2.3-3.5,4.7c0,2.2,0.8,4.7,3.4,4.7
	c0.5,0,1-0.1,1.4-0.4v-3.1h-2.1c-0.5-0.2-0.5-1.8,0-2h3.7c0.4,0,0.6,0.5,0.6,0.9v5.3c0,0.4-0.1,0.7-0.5,0.9c-1,0.5-2.2,0.7-3.3,0.7
	c-4.1,0-5.6-3.3-5.6-6.7c0-3.6,1.7-7,5.7-7c1.2,0,2.6,0.3,3.5,1.1C51.2,152.4,51.4,154.9,50.6,154.3z M59.5,164.6H54
	c-0.5,0-0.7-0.3-0.7-0.9v-11.6c0-0.5,0.2-0.8,0.7-0.9h5.5c0.5,0,0.5,2.1,0,2.1l-3.9,0v3.3h3.3c0.4,0,0.4,2.1,0,2.1h-3.3v3.8h4
	C60,162.5,60,164.6,59.5,164.6z M63.7,164.2c0,0.6-2,0.6-2,0l0-12c0-0.6,0.3-1,0.8-1h1.2c0.9-0.1,1.3,0.6,1.6,1.4l3.5,8.3h0
	l-0.1-9.2c0-0.6,2-0.6,2,0l0,12c0,0.5-0.3,1-0.8,1h-1c-1,0-1.3-0.9-1.7-1.7l-3.7-8.7L63.7,164.2z M82.2,157.7c0,3.8-1.3,6.9-5.9,6.9
	h-2.6c-0.5,0-0.7-0.3-0.7-0.9v-11.6c0-0.5,0.2-0.8,0.7-0.9h2.8C80.7,151.3,82.2,154.1,82.2,157.7z M79.9,157.8
	c0-3.2-1.2-4.7-4.6-4.4v9.1C78.8,162.7,79.9,161.3,79.9,157.8z M28.9,68c-3.5,0.3-3.5-5.1,0-4.8h9.2V37.4c0-2.3,1.8-3.7,4.1-3.7H65
	c2.3,0,4.1,1.4,4.1,3.7v25.9h9.7c3.5-0.3,3.5,5.1,0,4.8H28.9z M35.1,28.4c-1,0-1.5,0.5-1.5,1.4v3.7c0,1.7-0.9,2.5-2.8,2.5
	c-1.9,0-2.9-0.8-2.9-2.5v-6.2c0-2.3,1.8-3.7,4.1-3.7h18.6v-3.4c-0.4-3.6,6.3-3.6,5.9,0v3.4h18.9c2.3,0,4.1,1.4,4.1,3.7v6.1
	c0.4,3.5-6.2,3.5-5.8,0v-3.7c0-0.9-0.5-1.4-1.5-1.4H35.1z M63.2,43.6v-3.9c0-0.9-0.5-1.4-1.5-1.4H45.1c-1,0-1.5,0.5-1.5,1.4v3.9
	H63.2z M63.2,53.3v-5.4H43.6v5.4H63.2z M43.6,57.7v5.5h19.6v-5.5H43.6z M42.9,83.6c3.1-0.3,3.1,4.5,0,4.2H27.6
	c-3.1,0.3-3.1-4.5,0-4.2H42.9z M65.5,84.3c-0.1-3.3-0.1-5.9-0.1-7.6c-0.5-3.2,5.3-3.2,5-0.1c0,3.5,0,6,0.1,7.7H79
	c3.5-0.3,3.5,5.1,0,4.8h-8.4c0.2,6.7,0.6,12.3,1.2,16.7c1.5-3.2,2.7-6.5,3.7-10c0.3-3.4,6-1.8,4.8,1.3c-1.7,5.8-4.1,11.2-7.2,16.3
	c1,4.5,2.3,6.8,3.7,6.9c2-0.1,1-8.8,4.7-6.5c2.8,3.8-2,13.6-4.8,12.5c-3.2-0.1-5.6-2.7-7.4-7.7c-1.7,2.2-3.6,4.2-5.7,6.2
	c-2,2-6.3-1.2-3.2-3.1c2.6-2.3,5.1-5.2,7.5-8.8c-1.1-5.4-1.8-13.3-2.2-23.7H54.3c-1,0-1.5,0.5-1.5,1.4V96H62c1.2,0,2,0.7,1.9,1.9
	c-0.5,8.7,0.3,12.8-1.6,17.8c-1.9,3.1-11.4,2.4-8.5-2.3c0.6-0.8,3.5,0,4.3-1c0.4-0.6,0.7-3.7,0.9-9.4c0-1.6,0-2.5-1.4-2.6h-4.6
	c-0.1,9.8-1.4,17.6-4.1,23.3c-0.8,1.9-4.8,0.8-5.5-0.7H31.7c-2.3,0-4.1-1.4-4.1-3.7v-9.2c0-2.3,1.8-3.7,4.1-3.7h7.9
	c2.3,0,4.1,1.4,4.1,3.7v12.2c2.7-5.1,4-12.9,4-23.3V88c0-2.3,1.8-3.7,4.1-3.7H65.5z M41.4,91.3c3-0.3,3,4.4,0,4.1H30
	c-3,0.3-3-4.4,0-4.1H41.4z M30,102.9c-3,0.3-3-4.4,0-4.1h11.4c3-0.3,3,4.4,0,4.1h-5.7H30z M41.3,76.1c3-0.3,3,4.4,0,4.1H30.4
	c-3,0.3-3-4.4,0-4.1H41.3z M32.2,117.5c0,0.9,0.5,1.4,1.5,1.4H39v-6.8c0-0.9-0.5-1.4-1.5-1.4h-3.7c-1,0-1.5,0.5-1.5,1.4V117.5z
	 M76.7,81.6c-0.7-1.1-2.1-2.6-2.9-3.5c-2.5-2,1.8-4.3,3.3-2.5c1.3,1.2,2.5,2.5,3.7,4C82.5,81.9,77.7,83.9,76.7,81.6z M80.6,10.6
	H26.8c-1.8,0-1.8-2.8,0-2.8h53.8C82.5,7.7,82.5,10.6,80.6,10.6z M80.6,142.2H26.8c-1.8,0-1.8-2.8,0-2.8h53.8
	C82.5,139.4,82.5,142.2,80.6,142.2z M80.6,4.7H26.8c-1.8,0-1.8-2.8,0-2.8h53.8C82.5,1.9,82.5,4.7,80.6,4.7z M80.6,136.4H26.8
	c-1.8,0-1.8-2.8,0-2.8h53.8C82.5,133.5,82.5,136.4,80.6,136.4z"/>
<path class="st0" d="M72.8,244.1v2.6H57.9v3.5h13.2v2.6H57.9v3.8h16.6v2.6H35.3v-2.6h16.7v-3.8H38.7v-2.6h13.3v-3.5H37v-2.6h14.9
	c0-1.2,0-2.7-0.2-3.8l5.5,0c1-0.2,1,1,0.6,1.6v2.2H72.8z M46.2,276.3v8.1h-5.8v-22.8h29.1v17.1c0,4.6-2,5.3-5.5,5.3h-6.8l-0.9-3.2
	c6,0.8,7.4,1,7.4-1.1v-3.4H46.2z M63.6,267.6v-3.3H46.2v3.3H63.6z M63.6,273.7v-3.5H46.2v3.5H63.6z M92.8,253.3v2.6h-4v16.7
	c1.8-0.7,2.9-1.1,4-1.6v2.8c-2.8,1.8-4.3,2.5-12.1,6.3c-0.7,0.6-1.1,1-1.4,0.3l-1.1-4.3c1.7-0.3,3.2-0.8,5.1-1.4v-18.7h-4.3v-2.6
	h4.3c0-2.5,0.1-10.1-0.1-12.5l5.4,0c0.8,0.1,0.5,0.7,0.3,1.5v11H92.8z M107.9,283.3h-5.1v-10h-3.4v11h-5.4V254h8.4v-4.3h-10v-2.6h10
	c0-1.4,0-5.5-0.1-6.4l5.5,0.1c0.8,0,0.4,1,0.2,1.5v4.8h4.4c-0.6-1.4-0.8-1.8-2.9-5l2.3-1.5c2.5,1.6,3.2,2.1,5.4,3.9l-4.6,2.6h5.3
	v2.6h-10v4.3h8.4v25.8c0,2.3-0.3,4.2-3.5,4.2H110l-1.1-2.7c1.8,0.5,2.4,0.6,2.4-1.8v-6.2h-3.4V283.3z M102.9,256.5h-3.4v5.7h3.4
	V256.5z M102.9,264.9h-3.4v5.8h3.4V264.9z M107.9,262.2h3.4v-5.7h-3.4V262.2z M107.9,270.6h3.4v-5.8h-3.4V270.6z M136.3,262.7
	l-9.9-16.8h2.7l8.6,15h0.2l8.7-15h2.4l-10,16.7l10.6,17.7H147l-9.2-15.9h-0.2l-9.2,15.9H126L136.3,262.7z M167.6,260.6V275
	c0,4.1,3.1,6,8.3,6c6.7,0,16,0.1,22.6-0.7l-1.2,3.3h-23c-4,0-7.2-0.3-9.5-4.4c-0.6,0.8-4.3,4.3-4.4,4.5c-0.4,0.7-0.5,0.8-1,0.7
	l-1.6-4.9c0.7-0.2,2.3-0.7,4.6-2.3v-13.9h-3.9v-2.6H167.6z M164.4,254.8c-1.2-4.2-3.2-8.9-5.5-12.6l1.8-1.6
	c2.6,2.2,7.1,7.5,8.8,10.3L164.4,254.8z M172.9,256.9v-15.1h20.9v15.1h2.4v16.9c0,1.5,0,4.4-4,4.4h-5.5l-0.9-2.7
	c0.9,0.1,2.2,0.2,3.1,0.2c1.8,0,1.9-0.7,1.9-2v-14.2h-15v19h-5.4v-21.6H172.9z M188.7,262.2v11.1h-11v-11.1H188.7z M180.8,256.9
	v-8.7h7.5v-3.8h-10v12.5H180.8z M181.4,264.9v5.9h3.5v-5.9H181.4z M188.3,256.9v-6.2h-2.9v6.2H188.3z M216.6,262.2v2.6h-10.8v-2.3
	c-1.1,0.9-2,1.5-2.8,2l-2-2.2c1.3-1.1,5.4-4.6,6.9-10.1h6.8c1.4,4.1,3.3,7,6,9.5l-1.8,2.1c-3.3-2-5.4-4.1-7.6-7
	c-1,1.4-2.1,2.8-5.3,5.4H216.6z M212.2,284.4h-5.2v-13.8H202V268h17.2v8.4c0,3.1-0.6,4-3.3,4h-2.5l-0.8-2.5c1.1,0,2,0.6,2-0.7v-6.5
	h-2.4V284.4z M218.9,243.1c0-0.8-0.1-1.9-0.2-2.8l5.7,0.1c0.5,0.2,0.3,0.5,0.1,1.3v5.9h8c0-1.5,0.1-4.5-0.1-5.9l5.6,0.1
	c0.6,0.3,0.2,0.6,0.1,1.2v7.1h-32.8c0-1.9,0.1-6.7-0.1-8.5l5.6,0c0.5,0.1,0.2,1,0,1.4v4.5h8.1V243.1z M217.2,282.3
	c2.7-1.1,5.1-2.9,7-5.3h-2.8v-19.3h4.9c0.3-0.8,0.3-1.1,0.7-2.7h-7.1v-2.6h21.5v2.6h-8.9c-0.4,0.6-1,1.5-1.9,2.7h9.1V277h-3.9
	c0.8,1,3,3.5,6.5,5.3l-1.6,2.2c-4.7-1.3-7.1-2.8-9.4-4.7l3.2-2.8h-8.5l3.1,2.5c0,0,0.2,0.8-1,0.9c-3.9,2.8-7.7,3.7-8.8,3.9
	L217.2,282.3z M226.7,263.3h7.5v-2.9h-7.5V263.3z M234.2,265.8h-7.5v2.9h7.5V265.8z M234.2,271.3h-7.5v3.1h7.5V271.3z M277.3,251.3
	v0.6c2.1-3.3,3.7-8.8,4.1-11.7l5.2,1.1c0.7,0.1,0.2,1-0.1,1.2c-0.4,0.8-0.7,1.4-1.9,3.3h3c0.6-1.8,1-3.3,1.4-5.2l5.1,1.1
	c1.1,0.1-0.1,1.2-0.5,1.4c-0.8,1-1.6,2.1-2.3,2.8h5.9v2.6h-4.5v2.9h3.7v2.6h-3.7v2.9h3.7v2.6h-3.7v3.2h5.4v2.6h-17.9v-13.2
	c-0.7,0.8-1,1.1-1.6,1.8l-1.3-1.7v1.8h-2.8v2.9h2.8v2.6h-2.8v3.2h3.8v2.6H262v-11.8c-0.9,1.1-1.7,1.9-2.4,2.6l-1.7-2.1
	c1.1-1.5,4.8-6.3,6.1-13.8l5.4,1.2c0.6,0.1,0.3,0.9,0,1.1c-0.3,0.3-1.6,2.8-1.9,3.3h2.8c0.7-1.6,1.3-3.4,1.7-5.3l5,1.2
	c0.9,0.3,0,1-0.4,1.2c-0.4,0.4-2.2,2.5-2.5,2.9h4.1v2.6h-3.6v2.9H277.3z M297,284.4c-5.2-0.4-12.7-1.5-18.4-3.8
	c-3.5,1.5-10.2,3.2-18.3,3.9l-1.3-2.6c7.6-0.5,13.6-3,14.9-3.5c-1-0.6-4.6-2.8-7.2-6.7l5.7-1.4H261v-2.6h31.9v2.5
	c-3.2,4.4-9,7.8-9.7,8.2c2.2,0.9,9.2,3.3,15,3.4L297,284.4z M269.8,251.3v-2.9H267v2.9H269.8z M267,254v2.9h2.8V254H267z
	 M269.8,259.5H267v3.2h2.8V259.5z M273.1,270.3c1.8,3,4.1,4.7,5.4,5.7c4.2-2.6,5.6-4.6,6.4-5.7H273.1z M287.9,248.5h-2.7v2.9h2.7
	V248.5z M287.9,254h-2.7v2.9h2.7V254z M287.9,259.5h-2.7v3.2h2.7V259.5z M319.4,249.6c0-2.5,0-6.7-0.1-9.2l5.8,0.1
	c0.5,0.2,0.3,0.8,0.1,1.5v7.6h14.5v2.6h-14.5v12h12.5v2.6h-12.5v13.8H342v2.6h-39.2v-2.6h16.6v-13.8h-12.5v-2.6h12.5v-12h-9.2
	c-2.8,5.1-5.2,7.9-6.3,9.1l-2-1.5c1.1-1.8,4.9-8.2,6-18.6l6.1,1.4c0.3,0.2,0.2,0.9-0.2,1.2c-0.8,2.4-1.4,4.1-2.3,5.9H319.4z
	 M352.8,263.4c-1.9-3.4-3.5-5.4-6.6-8.9l1.4-2.2c4,2.1,6.2,3.3,9.2,5.7L352.8,263.4z M358.2,265.3c-0.8,2.3-2.6,7.5-3.2,9
	c-1.2,3-3.3,8.4-3.6,9.1c0,0.4-0.2,1.5-0.6,1.1l-4.4-3.9c3-3.5,5.1-7.2,9.8-17.4L358.2,265.3z M353.9,250.7c-2-3.7-4.4-6.6-6.1-8.4
	l1.5-2.1c4.2,2.3,6.2,3.7,8.6,5.5L353.9,250.7z M374.8,253.9H386v2.6h-11.3v7.8h9.3v20.2h-5.8v-2.3h-12.8v2.3h-5.8v-20.2h9.3v-7.8
	H358v-2.6h10.9v-7.4c-4,0.2-6.9,0.2-9.1,0.1l-1.2-2.8c13,0,20.5-2.5,22.5-3.7l3.2,4.1c0.7,0.8-1.2,0.7-1.9,0.8
	c-3.2,0.6-5.4,0.8-7.6,1V253.9z M378.3,279.6V267h-12.8v12.7H378.3z M397.4,282.5v1.8h-5.6V242h36.1v42.2h-5.7v-1.8H397.4z
	 M415.6,271.1c0.6,0.3,2.5,0.8,4.1,1.1c-0.7,5.2-3,5.4-4.8,5.4h-9c-3.4,0-3.8-0.9-3.8-4.3v-7.3c-1.1,1-2.1,1.8-3,2.5l-1.6-2.1
	c2.9-2.5,4.1-4.2,4.9-5.4h-3.9v-2.6h5.4c0.6-1.3,0.8-1.8,1.3-3.1h-3v-1.3c-1.1,1.5-1.7,2.3-2.3,3l-1.6-1.3c1.8-3.3,2.6-5.1,3.5-9.1
	l4,0.9c0.3,0.3,0,1-0.4,1.3c-1.2,2.2-1.8,3.1-2.3,3.9h2.9c0.5-2.7,0.9-4.4,0.6-6.1l5.4,0c0.5,0.1,0.2,0.8,0.1,1.1
	c-0.2,1.2-0.4,2.5-1.1,5h5.9c-0.8-1.3-1.6-2.4-3.4-4.4l1.8-1.5c3,2.1,5.4,4.5,6.9,6.1v-8h-24.9v35.2h24.9v-26.8l-3.3,3.1
	c-0.5-1-0.6-1.3-0.9-1.8v0.7h-2c0.3,1.7,0.7,2.6,0.8,3.1h4.5v2.6h-3.4c1.6,3.1,3,4.5,4.1,5.4l-1.6,2.1c-1.3-1-2.8-2.2-4.7-4.4
	C415.8,264,415.8,270.9,415.6,271.1z M415.7,263.9c-0.4-0.5-1.4-1.6-2.2-3.1h-6.7c-0.5,0.8-1.1,1.5-2.6,3.1H415.7z M409.6,272.8
	l-0.8-2.7c1.7,0.3,2.3,0.8,2.3-0.9v-2.7H407v6.7c0,1.4,0.1,1.7,1.5,1.7h4.6c1,0,1.8,0,2.1-3.1c-0.6,0.8-1.3,1-2.9,1H409.6z
	 M410.1,255.1c-0.7,1.6-1.2,2.5-1.6,3.1h3.6c-0.7-1.5-1-2.5-1.2-3.1H410.1z M15.6,339l0.4,1.2c-3.9,2.4-11.6,4.8-14.8,5.5l-0.7-2.9
	c2.3-0.4,5.9-1.2,9.2-2v-13.4H0.7v-1.7h8.9v-12.8h2.5v27.2C13.4,339.7,14.6,339.3,15.6,339z M26.1,344.6c-1.4,0.1-3.4,0.1-5.2,0
	c-3.3-0.1-3.9-1-3.9-5.1v-26.6h2.5v14.5c2.2-1.6,5-3.9,7.4-6.4l1.9,2.4c-2.8,2.3-6.7,4.4-9.3,5.6v10.5c0,1.8,0.2,2.3,2.1,2.5
	c1.3,0.1,3,0.1,4.4,0c1.6-0.2,1.7-1,1.7-8.2h1.1C29.9,342.7,30,344.4,26.1,344.6z M67.5,343.4l-2.1,2.6c-2.6-3.1-4.7-7.4-5.4-15
	c-0.7,6.7-2.8,11.9-8.1,15.5l-0.6-1c1.1-0.9,2-2,2.8-3.1c-1,0.6-2.2,1-3.5,1.1c-2,0.1-4.1,0.1-5.8,0c-4.6-0.2-5.4-0.8-5.4-5.4v-21.6
	h-1.2v-1.5h16.7v1.5H41.7v21.8c0,2.1,0.2,2.7,3.1,2.9c1.7,0.1,3.8,0.1,5.4,0c1.5-0.1,2.4-0.3,3.7-1.2l0.9,1.5
	c2.3-3.9,3.2-8.9,3.2-15.3v-2.5h2.5v2.8C61.2,334.2,63.8,339.7,67.5,343.4z M43.1,328.7h5v9.6h-5V328.7z M52.7,326.2h-8.5v-7.5h8.5
	V326.2z M44.8,336.9h1.6v-6.8h-1.6V336.9z M50.6,320.2h-4.3v4.7h4.3V320.2z M54.4,338.3h-5v-9.6h5V338.3z M52.7,330.1h-1.6v6.8h1.6
	V330.1z M53.7,327.3c1.7-4,2.7-10,3.1-14.7l2.6,0.5c-0.2,1.5-0.5,3.8-1.2,6.2h5.5l0.9-0.6l2.4,1.4c-0.8,2.3-2.7,6.1-4.1,7.9
	l-0.9-0.5c0.7-1.8,1.6-4.7,1.9-6.7h-6c-0.8,2.7-1.8,5.4-3.1,7.2L53.7,327.3z M105,342.5v1.8H77.3v-1.8h12.6v-9.4h-9.6v-1.7h9.6v-8
	h-7.1c-1.3,2.9-3,5.6-4.9,7.5l-0.8-0.8c2.7-3.9,4.7-9.4,5.7-16.1l2.8,0.9c-0.5,2.3-1.2,4.6-2,6.8h6.4v-9.1h2.6v9.1h10.3v1.6H92.5v8
	h9.4v1.7h-9.4v9.4H105z M118.9,327.5c-1.3-1.9-3.7-4.8-5.1-6.3l0.6-1c1.9,1.1,4.6,3.1,6.4,4.9L118.9,327.5z M120.3,329.5l1,0.4
	c-0.7,4.5-2.4,11.8-3.6,15.5l-2.8-1.4C116.5,340.2,118.9,334.1,120.3,329.5z M116,312.7c1.7,1,4.4,3.1,6,4.9l-1.9,2.3
	c-1.3-1.9-3.4-4.5-4.8-6.2L116,312.7z M133.7,325.5v6.3h7.1v14.1h-2.5v-2.5h-11.6v2.7h-2.5v-14.3h6.9v-6.3h-8.9V324h8.9v-6.3
	c-2.9,0.5-5.7,0.9-7.6,1l-0.2-1.2c4-0.6,11.5-2.6,15.4-4.7l2.5,2.4c-1.9,0.7-4.6,1.4-7.4,2v6.8h9.3v1.5H133.7z M126.7,333.3v8.5
	h11.6v-8.5H126.7z M177.6,345.7c-3.7-2.3-3.9-12.5-3.9-21.7V316h-17v8.5c0,12.8-1.6,18.2-4.8,22l-0.8-0.8c2.8-4.8,3.1-9.9,3.1-21.2
	v-10h22v9.3c0,9.3,0.2,15.1,1.6,17.9c0.9,2.2,1.1-0.1,1.1-5.9h1C181.3,343.5,180.1,347.3,177.6,345.7z M170,335.8
	c1.6,2.1,3.2,5.2,4.3,7.9l-2.2,1.1c-0.3-1.1-0.7-2.4-1.2-3.7c-4.1,1.4-10.2,2.7-13.7,3.2l-0.5-2.9c2.3-0.1,4.9-0.3,7.4-0.6v-6.3
	h-5.4v-9.3h5.4v-4.1h-6.2v-1.5h14.6v1.5h-6.1v4.1h5.4v9.3h-5.4v6c1.4-0.2,2.8-0.4,4-0.6c-0.5-1.3-0.9-2.5-1.4-3.5L170,335.8z
	 M164.1,333v-6.3h-3.1v6.3H164.1z M166.4,333h3.1v-6.3h-3.1V333z M197.6,326.3v20h-2.4v-18.2c-1,3.3-3,7.3-4.7,9.8l-0.8-0.8
	c1.7-3.3,4-9.1,5.1-14.9h-4.5v-1.5h4.9v-8.1h2.4v8.1h4.4v1.5h-4.4v2c1.4,1.5,3.2,3.8,4.3,6l-2,1.5C199.3,330,198.4,328,197.6,326.3z
	 M219,330.9l-1.3,3.1c-2.8-0.9-5.9-2.6-8.5-5.3c-2.7,2.7-6,4.6-10,6.1l-0.5-1.1c3.7-1.7,6.8-4,9.1-6.6c-1.1-1.5-2.1-3.3-2.9-5.4
	c-1.1,1.8-2.4,3.4-3.8,4.8l-0.8-0.8c2.5-3.2,4.3-7.4,5.4-12.9l2.6,0.8c-0.4,1.3-0.7,2.5-1.2,3.7h5.2l0.7-0.6l2.5,1.3
	c-1.1,3.7-2.8,6.7-4.9,9.2C213,328.9,215.9,330.1,219,330.9z M202.7,333.8h13.1v12.3h-2.4v-2.4h-8.2v2.4h-2.4V333.8z M205.1,342.2
	h8.2v-6.9h-8.2V342.2z M209,325.6c1.6-2.2,2.8-4.5,3.4-6.9h-5.9c-0.3,0.6-0.6,1.3-1,1.9C206.5,322.6,207.6,324.2,209,325.6z
	 M230.7,324.9h-2.5v-7.2h13.9c-1.4-1.5-3.1-3.1-4.7-4.5l0.5-1c1.7,0.6,4.6,2,6.3,3.4l-1.3,2.1h11.7v7.2h-2.4v-5.7h-21.4V324.9z
	 M254.1,335.1c0.8,7.9,0.7,9.5-2.7,9.7c-2.8,0.2-6,0.3-8.9,0.1c-2.8-0.2-3.6-1-3.6-4.4v-6.3c-3.5,0.6-7,1.2-10.4,1.7l-0.3-1.5
	c3.5-0.5,7.1-1.1,10.6-1.8v-6.1c-2.8,0.8-5.8,1.5-8.8,1.9l-0.2-1.2c7.9-1.7,13.8-4.9,16.5-6.8l2.5,2.1c-2,1.2-4.6,2.3-7.4,3.2v6.4
	c4.4-0.8,8.7-1.6,12.9-2.4l0.2,1.5c-4.2,0.8-8.7,1.7-13.1,2.5v6.1c0,1.7,0.3,2.3,1.9,2.4c2.3,0.1,4.7,0.1,7.3,0
	c1.8-0.1,2.2-0.6,2.3-7H254.1z M270.1,335.6c2.5-1.3,3.8-3.7,4-6.2c-0.3,0.3-1,0.4-1.3,0.4c-3.1,0.2-3-5.5,0.1-5.4
	c6.1,0.7,2.4,11.4-2.1,12.1L270.1,335.6z M315,345.7c-2.8-0.9-4.9-3.4-6.3-6.9c-2.8,2.9-6.2,5.1-10.3,6.6l-0.5-1.2
	c3.8-1.9,7.1-4.4,9.9-7.8c-1.3-4.2-1.9-9.5-2.1-15h-10.6v5.3h8.4c-0.1,5.5-0.2,8.5-0.4,10.4c-0.3,3.2-3.1,4-7.8,1.3l0.3-1.2
	c4.4,1,5,0.8,5.1-0.9c0.1-1.4,0.2-4,0.3-8h-5.9c0,10.1-1.6,14.5-4.9,18l-0.8-0.8c2.4-3.7,3.1-6.2,3.1-16.9v-8.7h13.2
	c-0.1-2.2-0.1-4.8-0.1-6.9h2.5c0,2.1,0,4.6,0.1,6.9h5.5c-1.2-2-2.6-4.1-3.8-5.9l0.7-0.9c1.9,1.5,3.7,3.3,5.1,5l-1.7,1.7h4.4v1.7
	h-10.2c0.1,4.6,0.6,8.9,1.6,12.3c1.7-2.8,3.1-6,4.2-9.8l2.5,1.2c-1.3,4.3-3.3,8.2-5.9,11.4c1.2,3.2,2.8,5.5,4.6,6.2
	c1.3,0.5,1.6,0,1.5-7.5h1.1C319.2,342.9,319,346.9,315,345.7z M356.3,340.5l-1.6,2.9c-3.5-2.4-6.4-6.2-8.2-11.7
	c-0.6,0.4-1.2,0.7-1.9,1c2.2,7.6,1.6,17.2-8.1,11.6l0.3-1.2c6.5,1.8,6.8-0.5,6.2-7.3c-2.6,2.9-7.4,6.3-14.3,8.2l-0.4-1.2
	c6.2-2.3,11-5.9,14.1-10.2c-0.2-0.8-0.4-1.5-0.7-2.2c-3.1,2.8-8,5.4-12.8,6.8l-0.4-1.2c4.9-1.8,9.3-4.6,12.2-7.9
	c-0.3-0.5-0.5-1.1-0.9-1.6c-3,1.9-6.7,3.5-10.2,4.3l-0.3-1.2c5-1.8,7.9-3.7,10.2-5.9h-7.1v-1.5h18.7v1.5h-8
	c-0.7,0.7-1.5,1.4-2.3,2.1c1.3,1.7,2.4,3.6,3.1,5.5c2.1-1.7,4.2-3.8,5.9-6l2.1,1.8c-1.5,1.4-3.1,2.7-4.7,3.9
	C349.5,335.5,352.8,338.7,356.3,340.5z M331.2,324.3h-2.4v-7h14c-1.6-1.3-4-2.9-5.7-4l0.4-1.1c2.2,0.6,5.4,1.9,7.2,3l-1.1,2.1h11.5
	v7h-2.4v-5.5h-21.5V324.3z M380.7,321.1c-0.6,1.4-1.4,3-2.2,4.3h10.9v20.7h-2.7v-2.5h-14.2v2.6H370v-20.8h7.1
	c0.3-1.4,0.6-2.9,0.7-4.3h-12.6v-1.8h9.6c-1-2.2-2.2-4.4-3.3-6.3l0.8-0.9c1.6,1.4,3.7,3.8,5,5.8l-1.8,1.3h6.2c1-1.9,1.9-4.2,2.7-6.9
	l2.8,1.3c-1.4,2.4-2.9,4.3-4.1,5.6h10.4v1.8H380.7z M386.8,326.9h-14.2v3.9h14.2V326.9z M386.8,332.3h-14.2v4.1h14.2V332.3z
	 M386.8,337.8h-14.2v4.2h14.2V337.8z M402.9,344.5c1.5-1.3,3-2.8,4.4-4.7v-8.4h-4v-1.5h6.4v9.7c1.4,2.7,3.8,3,9.1,3
	c4.1,0,10-0.3,13.8-0.8l-1,3c-3.5,0.3-9.4,0.3-13,0.1c-6.4-0.3-8.3-1.5-10-4.8c-1.3,2.6-2.6,4.4-4,6.2L402.9,344.5z M403.4,321.2
	l0.5-1c1.7,1,4.1,2.6,5.8,4.4l-1.8,2.3C406.7,325.1,404.9,322.8,403.4,321.2z M404.5,313.8l0.6-1c1.7,0.8,4.2,2.5,5.9,4.2l-1.7,2.2
	C408,317.5,406.1,315.4,404.5,313.8z M411.3,333.5h5v-3.3h-4.1v-1.5h4.1v-2.8h2.3v2.8h4.9v-2.9h2.3v2.9h4.5v1.5h-4.5v3.3h5.6v1.5
	h-20.1V333.5z M411.5,340.1c2.7-1.3,4.7-2.7,6.6-4.7l2,1.5c-2.3,1.9-4.9,3.2-8.1,4.2L411.5,340.1z M412.3,321.5v-6.8h7.7v5.4h-5.5
	v1.1c0,1.4,0.4,1.5,2.8,1.5c1.9,0,2.1-0.1,2.8-1.2l1.3,1.5c-0.8,1.4-1.8,1.7-4.1,1.7C413.4,324.9,412.3,324.6,412.3,321.5z
	 M418,318.8v-2.6h-3.5v2.6H418z M423.6,333.5v-3.3h-4.9v3.3H423.6z M421.9,321.5v-6.8h7.8v5.4h-5.6v1.1c0,1.4,0.4,1.5,2.7,1.5
	c2,0,2.4-0.1,3.1-1.2l1.3,1.5c-0.8,1.4-2,1.7-4.6,1.7C423,324.8,421.9,324.6,421.9,321.5z M422.2,336.7l0.4-1.1
	c2.6,0.6,6,1.9,8.5,3.2l-1.2,2.3C427.9,339.8,424.6,337.9,422.2,336.7z M427.6,318.8v-2.6h-3.6v2.6H427.6z M447.7,342.2
	c0-2.9,4.1-2.9,4,0C451.8,345.1,447.7,345.1,447.7,342.2z M448.1,315.6h3.3l-0.7,21.3h-1.8L448.1,315.6z"/>
<line class="st1" x1="351" y1="407.2" x2="120.3" y2="407.2"/>
<path class="st0" d="M85.3,529.3c-0.7,0-1.3,0-2,0.2v-3.7c12.8-2.2,4.6-15.6-3.7-7.6v-4.3c12-6.7,23.3,6.7,9.6,13.1v0.3
	c15,3.4,6.5,23.3-9.9,17.2l-0.2-3.3C92.2,545.7,95.9,531.2,85.3,529.3z M117,521.7c0.2,6.4-5.9,13.2-11.7,18.8v0.3h12.3v3.9H98.8v-3
	c6-5.2,13.2-14,12.8-19.5c0.9-8-8.2-7.4-12.1-3.5v-4.2C105.7,510.3,118,512.1,117,521.7z M135.4,528.5v4.4h-14v-4.4H135.4z
	 M139.1,545l-0.3-3.1c16.6,2.6,14.5-20.1,4.5-12.5l-3.8-0.6l0.5-15.8h14.8v3.8H145l-0.3,8.5l0.2,0.1
	C162.2,519.9,160.3,549.5,139.1,545z M159.3,529.3c-0.4-22.6,21.3-22.4,20.8-0.7C181.3,550.5,158.8,551.2,159.3,529.3z M174.5,528.7
	c0.6-17.1-10-17.5-9.6,0.4C164.5,546.5,175.4,546.1,174.5,528.7z M193.8,521.6v2h-3.1v13.1c2.2-0.8,2.6-1,3.4-1.3l-0.1,2
	c-1.8,1.1-8.4,4.3-9.3,4.8c-1.1,2.2-1.7-2.5-2-2.9c1.8-0.5,2-0.6,3.8-1.2v-14.6H183v-2h3.4c0-1.9,0.1-7.9-0.2-9.7
	c0.3,0.2,4.9-0.3,4.7,0.5c-0.5,0.3-0.2,8.5-0.3,9.2H193.8z M205.3,530.1h7.3v2h-7.3v13.7H201v-13.7h-7.7v-2h7.7v-3.7l-4.4,2.4
	c-0.3-2.8-1.3-7.7-2.1-10.5l1.7-0.9c2.3,3.5,4.3,7.8,4.7,8.8v-10.9h-6.7v-2h17.5v2h-6.6V530.1z M212.5,519.1c0.1,0,0.2,0.1,0.2,0.3
	c-1.9,3.6-3,6.5-5.2,9.5l-1.8-0.8c0.4-1.3,1.8-5.6,2.4-10.5L212.5,519.1z M225.3,549.6h-0.8v-39.8h0.8V549.6z M264.4,518.2v17.9H260
	v-1.7h-7v11.4h-4.3v-11.4h-7v1.7h-4.4v-17.9h11.4c0-2,0.1-4-0.1-6.4c7.2,0.2,3.9-1,4.5,6.4H264.4z M248.7,532.4v-12.2h-7v12.2H248.7
	z M253,520.2v12.2h7v-12.2H253z M281.1,531c-0.6,6.9-2.3,12-5,15l-1.3-1.2c1.3-2.6,2.1-5.8,2.5-10c-2.2,2-4.9,3.9-8,5.6l-1.4-4.6
	c1-0.2,2-0.5,3.1-0.9v-11.5h-2.7v-2.2h2.7v-9.1h3.7v9.1h2.5v2.2h-2.5v10.1c1-0.4,1.9-0.8,2.8-1.3c0.3-4,0.2-14.6,0.2-18.8h18.6v2.2
	h-15c0,3.5,0.1,10.7-0.2,14c0.8-1.4,1.7-3.5,2.3-5.8h-1.7v-2h2.1v-1.9c-0.7,0.1-1.3,0.2-1.9,0.3l-0.4-1.6c2.3-0.7,4.2-1.7,5.8-2.9
	l1.7,2.8c-0.7,0.3-1.6,0.6-2.5,0.8v2.5h2v2h-1.9v0.8c0.8,0.5,1.6,1.2,2.5,2.2l-1.5,1.9l-1-1.7v5.5h-2.7v-4.4
	c-0.7,1.3-1.5,2.6-2.4,3.4L281.1,531z M290.7,542.5h6v2.2h-17.8v-2.2h3.1v-8.1h3.3v8.1h1.8v-9.7h3.6v3.5h4.8v2.1h-4.8V542.5z
	 M294,523.8c1,2.4,2.4,4.4,3.4,5.5l-1.9,2.2c-0.7-1.2-1.2-2.6-1.6-3.9v5h-2.8v-4.7c-0.9,1.4-2,2.9-3.2,3.8l-0.8-1.1
	c1.2-1.4,2.7-3.9,3.5-6.7h-1.5v-2h2V520c-0.6,0.1-1.2,0.2-1.9,0.3l-0.3-1.4c2.5-1,4.1-1.9,5.6-3.1l1.7,2.8c-0.7,0.3-1.5,0.6-2.4,0.8
	v2.5h2.5v2H294z M312.2,519.2c-0.5,8.8-1,12.3-2.6,16.5c2.2,1.2,3.6,2.4,4.5,3.1l-2.6,3.7c-0.7-0.9-1.4-2-3.5-3.7
	c-2.6,4.4-5.9,6.4-7,7.1l-1.4-1.4c0.9-0.8,3.7-3,5.7-7.6c-2.7-1.6-4.8-2.4-5.9-2.8c0.8-2.9,2-8.7,2.6-12.9h-2.1v-2h2.4
	c0.5-3.7,0.6-5.1,0.7-7.5c6.7,0.8,4.2-0.6,3.5,7.5H312.2z M306,521.2c-0.8,4.8-1.7,9.2-2.4,11.8c0.9,0.4,1.6,0.6,2.6,1.1
	c1.1-4.2,1.5-8.7,1.6-12.8H306z M323.4,527.9h6v2h-6v11.4c0.9,5.6-5.5,4-9.1,4.2l-0.6-2.4c1.9,0,5.9,1.6,5.3-1.8c0,0,0-11.4,0-11.4
	h-7v-2h7v-7l2.1,0.2c1.3-2.9,1.8-3.9,2.2-5.8h-10.7v-2h16.4v2.3c-0.8,1.2-2,3.2-5.7,7.5V527.9z M361.5,517v2h-12.6v5.2h10.3v14.2
	c0.3,5.4-4.9,3.5-8.1,3.9l-0.6-2.3c1.4,0,4.6,1.2,4.3-1.3c0,0,0-12.5,0-12.5h-5.9v19.6h-4.5v-19.6h-5.9v16.2h-4.4v-18.2h10.2V519
	h-12.6v-2h12.6c0-1.5,0-3.6-0.1-5.2c6.6,0.1,4.2-1,4.6,5.2H361.5z M393.2,529.2c-5.5,15.7-20.5,16.5-29.2,16.6l-1-2.1
	c2.2,0,9.2-0.1,15.2-3.1c-0.6-2.3-1.1-3.7-2.2-5.6l1.2-0.9c2,1.3,3.5,2.5,4.9,3.9c3.2-2.7,4.6-5.7,5.1-6.8h-6.4
	c-2.9,2.2-7.3,4.5-13.6,6.3l-1.1-1.7c10-3.7,14-8.6,15.4-11.6c4.7,2.3,6.4,0.5,1.7,5H393.2z M372.3,519.5c2.6,1.5,4.5,3.1,4.7,3.3
	c1.7-1.5,3.6-3.5,4.8-6h-7.4c-3.5,3.9-8,6.3-9.5,7.1l-1.2-1.4c3.9-2.8,6.8-5.6,8.9-11c0.1,0.1,5.1,1.2,4.8,1.5
	c-0.1,0.3-1.1,1.4-1.3,1.8h12.7c-2.2,4.8-8.2,12.9-24.2,17.1l-1.2-2c5.6-1.4,9.3-3.8,10.1-4.3c-0.3-1.1-1.2-3.8-2.1-5.2L372.3,519.5
	z M29.2,491l0-4.8c7.6,7.8,19.7-1.7,7.4-7.4c-16-7.3-3.7-23,9-15.6v4.8c-8.3-8.7-17.5,2.3-5.6,7.1C53.2,480.5,47,498.8,29.2,491z
	 M65.7,479.2l5.9,13.2H66L61.5,480h-1.8h-2.5v12.6H52v-30.1c2.5-0.2,5.1-0.4,7.6-0.3c7.2,0.1,11.5,2.4,11.5,8.8
	C71.1,475.6,69.1,478,65.7,479.2z M65.9,471.1c0-4-2.4-5.3-5.8-5.4c-0.9,0-1.9,0-2.8,0.1v10.6h2.6C63.8,476.5,65.9,475.1,65.9,471.1
	z M87.8,465.1c-9.2,0-10.2,24.3,0.7,24.3c2.6,0,4.8-1.2,6.7-3v4.7c-1.6,1.3-4.2,1.9-7.4,1.9c-16.3,0.2-16.7-31.4-0.4-31.6
	c3.1,0,5.9,0.9,7.7,2.2v4.9C93.4,466.7,90.4,465.1,87.8,465.1z M110.5,472.7h-4v-5.1h-2.3c-0.5,3.3-1.6,5.2-4.6,8l-1.4-1.4
	c0.9-1.2,2.2-3,2.2-8.7v-4.4l3.8,0.5c2.2,0,5.1-0.6,7.1-1.6l1.5,2.6c0.2,0.2-0.1,0.4-0.5,0.4c-2.3,0.3-5.6,0.6-8,0.6v2.1h8.5v2h-2.4
	V472.7z M102.4,487.7v-14.2h22.2v14.2h-4.2c0.8,0.7,3.1,2.9,7.3,4.4l-1.4,1.7c-4.5-0.7-7.7-2.2-10.6-4.2l2.6-1.9h-10l2.6,1.7
	c0,0,0.1,0.1,0.1,0.1c0,0.4-0.5,0.5-0.8,0.6c-4,2.6-8.7,3.4-9.9,3.6l-1.4-1.7c5-1.6,7-3.9,7.5-4.4H102.4z M106.7,477.5h13.5v-2
	h-13.5V477.5z M120.2,479.5h-13.5v2.1h13.5V479.5z M120.2,483.5h-13.5v2.2h13.5V483.5z M111.6,472c1.5-1.9,2.5-3.4,2.5-8v-2.9
	l4.1,0.5c2.2,0,5.4-0.7,7.1-1.6l1.6,2.8c-2.2,1-8,0.6-8.8,0.8c0,0.4,0,1.7,0,2.1h9.9v2h-3.8v5.1h-4v-5.1h-2.4
	c-0.8,2.5-2.7,4.4-4.7,5.8L111.6,472z M157.7,492.9c-0.2-1.2-1.1-5-2.7-8.1l1.4-1.1c-0.8-0.3-1.7-0.9-3.3-3.3
	c-1.8,1.9-3.4,2.9-4.4,3.5l-1.5-1.6c1.3-1,2.9-2.3,4.5-4.6c-1.7-4-1.9-6.7-2.3-11.1h-11.6v4.1c0,6.7-2.1,10.4-4.8,13.4l-1.4-1.4
	c1.2-2.5,2.1-4.9,2.1-11.1v-7h15.6c-0.1-1.1-0.3-3.7-0.3-4.2l4.4,0.2c0.5,0,0.3,0.7,0.1,0.9c0,1.7,0,2.2,0,3.1h3
	c-0.8-1.6-1.7-2.7-2.3-3.4l1.5-1.3c0.7,0.3,2.8,1.4,4.5,2.6l-2.7,2h3.8v2h-7.7c0.1,2.3,0.3,4,0.8,6.4c0.3-0.7,1.3-3.4,1.4-5.1l4,1.9
	c0.3,0.2,0,0.6-0.3,0.9c-0.8,1.9-1.8,4.1-3.6,6.6c0.1,0.2,1.2,2.4,1.9,2.4c0.9,0,1.2-2.1,1.3-3.4c0.7,0.7,1.4,1.2,2.6,1.8
	c-0.5,2.6-1.6,6.6-4.8,5.9c1.7,1.6,3.5,4,4.7,6.1L157.7,492.9z M137.1,484.5c-0.3,3-0.7,5.5-1.8,8.8l-3.8-1.9c1.3-2,2.8-4.8,3.6-7.7
	L137.1,484.5z M148.5,469.4v2h-9.9v-2H148.5z M148.3,473.6v7.2h-9.4v-7.2H148.3z M143,483.2c0.5,0,0.5,0.6,0.2,1v5.9
	c0,1.3,0.4,1.3,1.6,1.3h4.2c1.3,0,2,0,2.6-4.7c1.5,0.9,2.7,1.3,3.9,1.6c-0.8,5-3.1,5-4.3,5H142c-2.4,0-2.9-0.6-2.9-3.5
	c0-1.7,0-5-0.1-6.7L143,483.2z M142.2,475.6v3.2h2.8v-3.2H142.2z M145.7,482.3c1.6,1.1,4.1,3.5,4.8,4.2l-4.2,2.8
	c-1-3.6-1.6-4.9-2.2-6.1L145.7,482.3z M177.8,467.2c0-1.9,0-5.1-0.1-7l4.3,0.1c0.4,0.1,0.2,0.6,0.1,1.2v5.8H193v2h-10.8v9.1h9.3v2
	h-9.3v10.5h12.4v2h-29.1v-2h12.3v-10.5h-9.3v-2h9.3v-9.1H171c-2.1,3.9-3.9,6-4.7,6.9l-1.5-1.1c0.8-1.4,3.7-6.2,4.5-14.2l4.5,1
	c0.2,0.2,0.2,0.7-0.1,0.9c-0.6,1.9-1.1,3.1-1.7,4.5H177.8z M203.3,477.7c-1.4-2.6-2.6-4.1-4.9-6.8l1-1.6c2.9,1.6,4.6,2.5,6.8,4.4
	L203.3,477.7z M207.4,479.2c-0.6,1.7-1.9,5.7-2.4,6.8c-0.9,2.3-2.5,6.4-2.7,6.9c0,0.3-0.2,1.1-0.5,0.9l-3.3-3
	c2.2-2.7,3.8-5.5,7.3-13.2L207.4,479.2z M204.2,468c-1.5-2.8-3.3-5-4.5-6.4l1.1-1.6c3.1,1.7,4.6,2.8,6.4,4.2L204.2,468z
	 M219.7,470.5h8.4v2h-8.4v6h6.9v15.4h-4.3V492h-9.5v1.8h-4.3v-15.4h6.9v-6h-8.1v-2h8.1v-5.6c-2.9,0.1-5.1,0.1-6.8,0.1l-0.9-2.1
	c9.7,0,15.3-1.9,16.7-2.8l2.4,3.1c0.5,0.6-0.9,0.5-1.4,0.6c-2.4,0.5-4,0.6-5.6,0.8V470.5z M222.3,490v-9.6h-9.5v9.6H222.3z
	 M249.1,488.8h12.4v3.8H243l0-3c22.3-19.6,10.7-31.2,0.7-22.6l0-4.2C257.4,455.6,271.9,468.4,249.1,488.8z M271.5,488.8h12.4v3.8
	h-18.6l0-3C287.7,470,276,458.4,266,467l0-4.2C279.8,455.6,294.3,468.4,271.5,488.8z M294.8,481.9c1.1-0.4,2.9-1.1,3.2-1.3v2.1
	c-1.2,0.8-4.8,2.8-9.2,5.2c-0.3,0.6-0.5,0.6-0.8,0.4l-1-3.8c1.3-0.1,2.8-0.6,3.7-1v-20h-3.2v-2h9.9v2h-2.5V481.9z M314.9,466.9v19.4
	h-3.3c1.1,1.7,3.3,4.3,5.7,5.6l-1.5,1.7c-2.2-0.8-5.5-2.5-8.4-5.3l2.9-2h-7.7l3,2c0.5,0.3-0.4,0.7-0.6,0.7c-3,2.4-6.5,3.9-9.2,4.7
	l-1.6-1.8c3.1-1.2,5.7-3.8,7-5.5h-2.5v-19.4h5.2c0.4-1.2,0.8-2.9,0.9-3.4h-6.6v-2h18.5v2h-7c-0.7,1.2-1.4,2.3-2.3,3.4H314.9z
	 M310.7,472.5v-3.7H303v3.7H310.7z M303,474.5v3.8h7.7v-3.8H303z M303,480.2v4.1h7.7v-4.1H303z M342.2,487.6
	c0.3,0.4,0.1,0.4-0.4,0.9c-0.8,1-1.8,2.1-2.1,2.3h9.2v2h-29.1v-2h8.1c0-0.2-0.6-2.2-1.3-3.7l0.9-0.5h-4.3v-6.3h22.2v6.3h-5.8
	L342.2,487.6z M348.6,476.9v2h-28.3v-2H348.6z M326.1,471.3v-1.7h1.8V468h-1.5v-1.7h1.5v-1.6h-1.8v-1.7h1.8c0-1,0-1.1-0.1-2.2
	l2.2,0.1c0.5,0,0.3,0.4,0.2,1v1.1h1.8v1.7h-1.8v1.6h1.7v1.7h-1.7v1.6h1.8v1.7h-1.8v2.1h2.6v-10.9c0-1.2,0-1.4-0.1-2l3.2,0.1
	c0.5,0,0.4,0.5,0.2,1v11.9h2.7v-2.1h-1.7v-1.7h1.7V468h-1.6v-1.7h1.6v-1.6h-1.7v-1.7h1.7c0-0.1,0-1.5,0-2.2l2.2,0.1
	c0.5,0,0.4,0.6,0.2,0.8v1.3h1.7v1.7h-1.7v1.6h1.5v1.7h-1.5v1.6h1.7v1.7h-1.7v2.1h2.5v-10.8c0-0.8,0-1-0.1-2.1l3.8,0
	c0.7,0.1,0.4,0.7,0.3,1.1v13.8h-26.3v-12.7c0-0.4,0-1.3-0.1-2.1l3.9,0.1c0.4,0,0.3,0.7,0.2,1v11.8h2.6v-2.1H326.1z M327.5,482.3v2.3
	h13.9v-2.3H327.5z M336.7,490.2c0.4-0.9,1.1-2.4,1.3-3.7h-8.5c1.4,1.4,2.4,2.6,2.8,3.2l-2.4,1h7.8L336.7,490.2z M363.2,460.3
	l4.2,0.1c0.6-0.1,0.6,0.7,0.2,1v1.9h11.8v9h-4.2v-7h-19.5v7h-4.2v-9h11.7L363.2,460.3z M357.5,492.1v1.5h-4.1v-14.1h24.3v14.1h-4.2
	v-1.5H357.5z M374.9,471.2v6.6h-18.8v-6.6H374.9z M374,467.6v2h-17v-2H374z M363.4,481.5h-5.9v3.3h5.9V481.5z M363.4,486.8h-5.9v3.4
	h5.9V486.8z M360.3,473.2v2.7h10.5v-2.7H360.3z M367.6,484.8h5.9v-3.3h-5.9V484.8z M367.6,490.1h5.9v-3.4h-5.9V490.1z M392.3,462.3
	c0.3,0.5,0.2,0.2-0.3,1.2c-1.7,5.2-4.8,11.3-9.6,15.5l-1.3-1.3c5.7-6.8,6.3-15.7,6.4-17L392.3,462.3z M397.9,471.4
	c0.1,0,0.2,0.1,0.2,0.2c0,0.2-0.3,0.6-0.5,1c-2.4,5.7-5.5,11.8-8.2,16.4c4.7-0.4,5.7-0.5,14.6-1.5c-0.9-1.9-1.8-3.9-4.4-8.5l1.5-1.2
	c1.4,1.7,6.7,8.1,9.4,13.4l-4,2.9c-0.3-1.2-0.7-2.2-1.7-4.8c-13.6,2.7-15.1,3-20.8,3.8c-0.5,0.3-0.6,0.5-0.9,0.1l-1.1-3.8
	c1.8,0,2.7,0,4.4-0.2c1.8-4,5.8-14,6.7-19.4L397.9,471.4z M404.8,461.3c0.2,7.5,2.8,12.3,6.5,16.4l-1.2,1.5
	c-5.4-4.5-8.4-8.7-9.8-15.8h-5.1v-2H404.8z M425.6,466.4v2h-12.2v-2H425.6z M431.4,477.8c0.9,3.8,1.7,5.4,2.7,6.8
	c1.8-2.9,2.3-5.5,2.5-7h-11.3v-2h16.3c-1.1,6.8-3.3,10-5.1,11.9c2.8,2.6,5.4,3.9,6.6,4.4l-1.3,1.6c-1.2-0.4-4.5-1.6-7.8-4
	c-3.6,2.6-7,3.6-8.9,4.1l-1.1-1.6h-5.9v1.6h-3.8v-12.2h10.2v10.5c2.6-0.9,5.1-2.4,7.2-4.4c-1.8-2-3.6-4.6-4.7-8.3L431.4,477.8z
	 M424.5,471.4v2h-10.1v-2H424.5z M424.5,476.4v2h-10.1v-2H424.5z M424.5,461.4v2h-10.1v-2H424.5z M418.1,483.5v6.7h2.7v-6.7H418.1z
	 M437.9,461.4v9.5c-0.1,0.8,0.5,0.6,1.1,0.6c1.2-0.8,0.6-2.2,0.9-4.5c0.7,0.4,1.9,1,3.3,1.3c-0.3,2.8-0.8,5.1-3.4,5.1h-3.1
	c-2.5,0-2.6-0.9-2.6-3v-7.1h-2.1c-0.1,4.5-0.3,8.7-5.6,11.6l-1.5-1.5c2.2-2,3.1-3.5,3.1-8.5v-3.6H437.9z"/>
</svg>

  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.section1 {
  width: 100%;
  height: 100vh;
  min-height: size(900);
  max-height: size(1080);
  //overflow: hidden;
  position: relative;
  z-index: 2; 
  margin: 0;
  background:url("./s1/bg.jpg");
  background-size: cover;
}
/*
  */
.t0{
  position: absolute;
  width: 100%;height:auto;
  top: 0;left: 0;object-fit: cover;
  opacity: 0.1;
  }

.img{
  position: absolute;left: 0;
      top:0;
      width:size(1040);
      height: 100%;
      object-fit: cover;
      border-radius: 0 40% 0 0;
      }

.logo{
  position: absolute;right:size(252);
      top:calc(50% + (295 - 540) * 100vw / 1920);
      width:size(375);
      transform: scale(1.2);
	.st0{fill:#FFF;}
	.st1{fill:none;stroke:#FFF;stroke-width:2.3;}
  }

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section1 {
    min-height: sizem(667);
    max-height: sizem(812);
    height:100vh;
  }
.img{
      top:auto;
      bottom: 0;
      width:95%;
      height:sizem(330);
      object-fit: cover;
      border-radius: 0 40% 0 0;
      }

.logo{
  position: absolute;right:0;left: 0;margin: auto;
      top:calc(50% + (50 - 667 * .5) * 100vw / 375);
      width:sizem(200);
      transform: scale(1.2);
  }


}
</style>
<script>
// @ is an alias to /src
import info from '@/info'
import { isPC, isMobile, isTablet } from '@/utils'

export default {
  name: 'section1',

  data() {
    return {
      info,
      isPC,
      isMobile,
      isTablet,
    }
  },

  methods: {},

  mounted() {},

  created() {},

  computed: {},
}
</script>
